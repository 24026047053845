.curios-card-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.cards-contentc {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media(max-width: 768px) {
    .cards-contentc {
        width: 85%;
    }
}
