.cardstate {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #fff;
}

.cardstate:hover {
    transform: scale(1.02);
}

.cardstate img {
    /* aspect-ratio: 10 / 7; */
    border-radius: 5px;
    width: 100%;
}

.cardstate span {
    margin-top: 5px;
    color: #fff;
    font-size: 17px;
    font-family: DejaVuSerif;
    text-shadow: 
    -1px -1px 0px #002776, 
    -1px 1px 0px #002776,                    
    1px -1px 0px #002776,                  
    1px 0px 0px #002776;
}