img {
    object-fit: contain;
}

footer {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    justify-content: space-evenly;
}

span {
    color: #fff;
    font-family: DejaVuSerif;
    text-align: center;
}

.footer-navigation {
    display: none;
}

.footer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

@media(max-width: 768px) {
    footer {
        gap: 25px;
    }
    .footer-navigation {
        display: block;
    }
}