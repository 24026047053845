@font-face {
    font-family: DejaVuSerif;
    src: url('../../assets/DejaVuSerifCondensed-Bold.ttf') format('opentype');
}

#banner {
    background-image: url('../../assets/CapaYoutube.jpg');
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#info {
    width: 90%;
    height: auto;
}

.section-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto 60px auto;
    gap: 20px;
}

h2 {
    font-family: DejaVuSerif;
    font-size: 28px;
    color: #fff;
    text-shadow: 
        -1px -1px 0px #002776, 
        -1px 1px 0px #002776,                    
        1px -1px 0px #002776,                  
        1px 0px 0px #002776;
}

.section-title {
    width: 100%;
    text-align: left;
}

.about-description {
    color: #fff;
    font-size: 18px;
    font-family: DejaVuSerif;
    width: 100%;
    margin: 0;
}

.button {
    width: 200px;
    height: 60px;
    background-color: red;
    color: #fff;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .8s;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: none;
    outline: none;
    margin-top: 25px;
}

.button:hover {
    color: red;
    background-color: #fff;
}

a:link {
    text-decoration: none;
}

.cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 40px;
}

@media(max-width: 768px) {
    #banner {
        height: 400px;
    }

    .about-container {
        text-align: center;
    }

    h2 {
        text-align: center;
    }

    #sobre {
        height: 520px;
    }

    .content {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .trailer {
        width: 100%;
    }

    #sinopse {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 0;
    }

    .button {
        width: 100%;
        margin-top: 30px;
    }

    .cards-container {
        grid-template-columns: 1fr;  
        margin-top: 30px;      
    }
}