@font-face {
    font-family: DejaVuSerif;
    src: url('../../assets/DejaVuSerifCondensed-Bold.ttf') format('opentype');
}

.intro-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
    gap: 20px;
}

.intro-title {
    font-family: DejaVuSerif;
    font-size: 36px;
    color: #fff;
    text-align: center;
    text-shadow: 
        -1px -1px 0px #002776, 
        -1px 1px 0px #002776,                    
        1px -1px 0px #002776,                  
        1px 0px 0px #002776;
}

.intro-description {
    width: 70%;
    color: #fff;
    font-size: 18px;
    font-family: DejaVuSerif;
    text-align: center;
}

@media(max-width: 768px) {
    .intro-description {
        width: 100%;
    }
}