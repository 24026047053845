.card {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 10px;
}

.card:hover {
    transform: scale(1.02);
}

.card span {
    color: #fff;
    font-size: 17px;
    font-family: DejaVuSerif;
    text-shadow: 
    -1px -1px 0px #002776, 
    -1px 1px 0px #002776,                    
    1px -1px 0px #002776,                  
    1px 0px 0px #002776;
}