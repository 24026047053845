@font-face {
    font-family: DejaVuSerif;
    src: url('../../assets/DejaVuSerifCondensed-Bold.ttf') format('opentype');
}

.info-state-container {
    margin: 40px auto;
    width: 90%;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    justify-content: center;
}

.flag-map-mun {
    width: 40%;
    min-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.state-flag {
    width: 100%;
    border-radius: 5px;
}

.state-map {
    width: 100%;
}

.info-mun {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    text-shadow: 
        -1px -1px 0px #002776, 
        -1px 1px 0px #002776,                    
        1px -1px 0px #002776,                  
        1px 0px 0px #002776;
}

.region-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.region-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px
}

.region-list h3 {
    font-family: DejaVuSerif;
    font-size: 24px;
    color: #fff;
    text-shadow: 
        -1px -1px 0px #002776, 
        -1px 1px 0px #002776,                    
        1px -1px 0px #002776,                  
        1px 0px 0px #002776;
}

.region-list span {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
}

@media(max-width: 890px) {
    .region-list {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;     
    }
}

@media(max-width: 768px) {
    .info-state-container {
        flex-direction: column;      
    }

    .flag-map {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .info-state {
        grid-area: 2 / 1 / 3 / 3;
    }
}
