@font-face {
    font-family: DejaVuSerif;
    src: url('../../assets/DejaVuSerifCondensed-Bold.ttf') format('opentype');
}

.info-state-container {
    margin: 40px auto;
    width: 90%;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    justify-content: center;
}

.flag-map {
    width: 30%;
    min-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.state-flag {
    width: 100%;
    border-radius: 5px;
}

.state-map {
    width: 100%;
}

.info-state {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    text-shadow: 
        -1px -1px 0px #002776, 
        -1px 1px 0px #002776,                    
        1px -1px 0px #002776,                  
        1px 0px 0px #002776;
}

.mun-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mun-container input {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    padding: 10px;
    border: none;
    outline: none;
    font-family: DejaVuSerif;
    color: #002776;
    font-size: 14px;
}

.mun-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px 10px;
}

.mun-list span {
    width: 250px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

.mun-list span:hover {
    outline: 1px solid #fff;
    background-color: #002776;
}

@media(max-width: 890px) {
    .mun-list {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;     
    }
}

@media(max-width: 768px) {
    .info-state-container {
        flex-direction: column;      
    }

    .flag-map {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .info-state {
        grid-area: 2 / 1 / 3 / 3;
    }
}
